<template>
  <div>
    <div
      class="app-page-spinner"
      v-if="findLoading"
      v-loading="findLoading"
    ></div>

    <app-project-release-form
      :isEditing="isEditing"
      :record="record"
      :saveLoading="saveLoading"
      :projectFaculty="projectFaculty"
      @submit="doSubmit"
      v-if="!findLoading"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectReleaseForm from '@/modules/project-release/components/project-release-form';
import ProjectStepEnum from '@/shared/enums/projectStep';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

export default {
  name: 'app-project-release-form-project-page',

  components: {
    [ProjectReleaseForm.name]: ProjectReleaseForm,
  },

  data() {
    return {
      projectStepEnum: ProjectStepEnum.values,
    };
  },

  computed: {
    ...mapGetters({
      record: 'projectRelease/form/record',
      findLoading: 'projectRelease/form/findLoading',
      saveLoading: 'projectRelease/form/saveLoading',
      pendingFiles: 'projectRelease/form/pendingFiles',
      status: 'projectRelease/form/status',
      projectFaculty: 'project/form/projectFaculty',
      projectId: 'project/form/projectId',
      projectReleaseId: 'project/form/projectReleaseId',
    }),

    isEditing() {
      return !!this.projectReleaseId;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.projectReleaseId);
      this.doFilterReleaseRequestsList();
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.status === 'completed' || this.status === 'revisionRequested' ||this.status === 'releaseRequested' || to.name.includes("error")) {
      next();
      return;
    }    

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(
          this.pendingFiles,
        );
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'projectRelease/form/doFind',
      doNew: 'projectRelease/form/doNew',
      doUpdate: 'projectRelease/form/doUpdate',
      doCreate: 'projectRelease/form/doCreate',
      doUpdateProject: 'project/form/doUpdate',
      doSetProjectReleaseId: 'project/form/doSetProjectReleaseId',
      doUpdateProjectStep: 'project/form/doUpdateProjectStep',
      doReloadProject: 'project/form/doReload',
      doFetchReleaseRequests: 'projectReleaseRequest/list/doFetch',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        const record = await this.doUpdate(payload);
        if (record) {
          if (record.status === 'completed') {
            /* await this.doUpdateProjectStep(
              this.projectStepEnum.funding,
            ); */
            this.navigateToProjectFunding();
          }
        }
        this.doReloadProject();
        this.doFilterReleaseRequestsList();
      } else {
        //project release is already created with the oproject on the server side
        await this.doCreate(payload.values);
        if (this.record) {
          await this.doReloadProject();
          await this.doSetProjectReleaseId(this.record.id);
          await this.doUpdateProject();
          this.doFilterReleaseRequestsList();
        }
      }
    },

    async doFilterReleaseRequestsList() {
      return this.doFetchReleaseRequests({
        filter: {
          projectRelease: this.record.id,
        },
      });
    },

    navigateToProjectFunding() {
      EventBus.$emit('navigateToProjectFunding');
    },
  },
};
</script>

<style>
</style>
