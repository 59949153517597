<template>
  <div>
    <form-wizard
      :start-index="getWizardStartIndex"
      @on-complete="doSubmitFinish"
      color="#393939"
      error-color="#ff4949"
      step-size="xs"
      layout="hortzontal"
      ref="formWizard"
      title=""
      subtitle=""
    >
      <tab-content icon="ti-user" title="Release">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formRelease"
          v-if="model"
          :disabled="isCompleted"
        >
          <el-form-item
            :label="fields.faculty.label"
            :prop="fields.faculty.name"
            :required="fields.faculty.required"
            :disabled="true"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-faculty-autocomplete-input
                :fetchFn="fields.faculty.fetchFn"
                :mapperFn="fields.faculty.mapperFn"
                :showCreate="!modal"
                mode="multiple"
                v-model="model[fields.faculty.name]"
                :disabled="true"
              ></app-faculty-autocomplete-input>
            </el-col>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :lg="12" :md="11" :sm="10"
              ><app-view-item-text
                :label="fields.status.label"
                v-if="isEditing"
                :value="presenter(record, 'status')"
              ></app-view-item-text
            ></el-col>
            <el-col :lg="6" :md="5" :sm="4"
              ><app-view-item-text
                v-if="isEditing"
                :label="fields.numberOfRequests.label"
                :value="presenter(record, 'numberOfRequests')"
              ></app-view-item-text
            ></el-col>
          </el-row>

          <app-project-release-request-list-table
            v-if="showReleaseRequestsTable"
            v-bind:isInPojectRelease="true"
          ></app-project-release-request-list-table>
        </el-form>
      </tab-content>

      <tab-content
        icon="ti-user"
        title="VR - Upload signed documents"
        v-if="!isInternalProject"
      >
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="formUploadSignedDocuments"
          v-if="model"
          :disabled="isCompleted || !hasPermissionToNotifyStep"
        >
          <el-form-item
            :label="fields.signedDocument.label"
            :prop="fields.signedDocument.name"
            :required="fields.signedDocument.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <app-file-upload
                :max="fields.signedDocument.max"
                :storage="fields.signedDocument.storage"
                :formats="fields.signedDocument.formats"
                :permissions="fields.signedDocument.permissions"
                v-model="model[fields.signedDocument.name]"
              ></app-file-upload>
            </el-col>
          </el-form-item>
        </el-form>
      </tab-content>

      <!-- Wizard Buttons -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <div class="wizard-btn" v-if="props.activeTabIndex > 0">
            <wizard-button
              v-if="props.activeTabIndex > 0"
              @click.native="props.prevTab()"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.previous"></app-i18n
            ></wizard-button>
          </div>
        </div>
        <div class="wizard-footer-right">
          <div class="wizard-btn">
            <wizard-button
              icon="el-icon-fa-floppy-o"
              @click.native="doRequestRelease"
              class="wizard-footer-right"
              v-if="
                !isReleaseRequested &&
                  !isReleased &&
                  !isVrNotified &&
                  !isCompleted &&
                  !(isCompleted || isRejected)
              "
              :style="props.fillButtonStyle"
              ><app-i18n code="common.requestRelease"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              v-if="saveBtnVisible"
              icon="el-icon-fa-floppy-o"
              @click.native="doSave"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.save"></app-i18n
            ></wizard-button>
          </div>
          <div class="wizard-btn">
            <wizard-button
              v-if="showFinishBtn"
              @click.native="doSubmitFinish()"
              class="wizard-footer-right finish-button"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.finish"></app-i18n
            ></wizard-button>
            <wizard-button
              @click.native="props.nextTab()"
              v-if="nextBtnVisible"
              class="wizard-footer-right"
              :style="props.fillButtonStyle"
              ><app-i18n code="common.next"></app-i18n
            ></wizard-button>
          </div>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectReleaseModel } from '@/modules/project-release/project-release-model';
import ProjectReleaseRequestForm from '@/modules/project-release-request/components/project-release-request-form';
import ProjectReleaseRequestListTable from '@/modules/project-release-request/components/project-release-request-list-table';
import ReleaseStatus from '@/shared/enums/releaseStatus';
import { ProjectReleasePermissions } from '@/modules/project-release/project-release-permissions';

const statusEnum = ReleaseStatus.values;
const { fields } = ProjectReleaseModel;
const formSchema = new FormSchema([
  fields.id,
  fields.project,
  fields.faculty,
  fields.projectReleaseRequest,
  fields.status,
  fields.numberOfRequests,
  fields.signedDocument,
  fields.updatedAt,
]);

export default {
  name: 'app-project-release-form',

  props: [
    'isEditing',
    'record',
    'saveLoading',
    'projectFaculty',
    'modal',
  ],

  components: {
    [ProjectReleaseRequestForm.name]: ProjectReleaseRequestForm,
    [ProjectReleaseRequestListTable.name]: ProjectReleaseRequestListTable,
  },

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      isMounted: false,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  mounted() {
    this.isMounted = true;
    this.model.faculty = this.projectFaculty;
  },

  //REMINDER HOW TO UPDATE MODEL
  watch: {
    record: function() {
      this.doUpdateModel();
    },
    model: {
      deep: true,
      handler: function() {
        this.$store.dispatch(
          'projectRelease/form/doSetFormModel',
          this.model,
        );
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      fundingAgencyEnum: 'project/form/fundingAgencyEnum',
      projectStatus: 'project/form/status',
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToNotifyStep() {
      return new ProjectReleasePermissions(this.currentUser)
        .notifyStep;
    },

    fields() {
      return fields;
    },

    isInternalProject() {
      return this.fundingAgencyEnum === 'selfFinancedProject';
    },

    isReleased() {
      return this.model.status === 'released';
    },

    isCompleted() {
      return this.model.status === 'completed';
    },

    isRejected() {
      return this.model.status === 'rejected';
    },

    isReleaseRequested() {
      if (this.status) {
        return this.status === 'releaseRequested';
      }
      return false;
    },

    nextBtnVisible() {
      if (!this.isMounted) {
        return false;
      }

      const tabIndex = this.activeTabIndex;
      switch (tabIndex) {
        case 0:
          return this.status === 'vRUploadSignedDocuments' ||
            this.isCompleted
            ? true
            : false;
        default:
          return false;
      }
    },

    saveBtnVisible() {
      if (!this.isMounted) {
        return false;
      }

      const tabIndex = this.activeTabIndex;
      switch (tabIndex) {
        case 0:
          return this.status === 'created' ? true : false;
        case 1:
          return this.status === 'vRUploadSignedDocuments' &&
            this.hasPermissionToNotifyStep
            ? true
            : false;
        default:
          return false;
      }
    },

    activeTabIndex() {
      return this.$refs.formWizard.activeTabIndex;
    },

    showFinishBtn() {
      return this.isMounted
        ? this.saveLoading ||
            (this.isLastStep &&
              this.hasPermissionToNotifyStep &&
              !this.isCompleted)
        : false;
    },

    isLastStep() {
      if (!this.isMounted) return;
      return this.$refs.formWizard.isLastStep;
    },

    isVrNotified() {
      return this.status === 'vRUploadSignedDocuments';
    },

    isRevisionRequested() {
      return this.status === 'revisionRequested';
    },

    showReleaseRequestsTable() {
      return this.status ? true : false;
    },

    isRequestedOrReleased() {
      return (
        this.status === 'releaseRequested' ||
        this.status === 'released'
      );
    },

    areRequestsReleased() {
      return this.isEditing &&
        this.record.projectReleaseRequest.length > 0
        ? this.record.projectReleaseRequest
            .map((f) => f.decision)
            .every((f) => f === 'approved')
        : false;
    },

    status() {
      return this.model.status;
    },

    getWizardStartIndex() {
      switch (this.status) {
        case statusEnum.vRUploadSignedDocuments:
        case statusEnum.completed:
          return 1;
        default:
          return 0;
      }
    },
  },

  methods: {
    presenter(record, fieldName) {
      return ProjectReleaseModel.presenter(record, fieldName);
    },

    isStatusNull(status) {
      return status === null;
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      let { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doRequestRelease() {
      await this.validateRelease();
      this.model.status = 'releaseRequested';
      this.doSubmit();
    },

    async doSubmitFinish() {
      await this.validateUploadSignedDocuments();
      this.model.status = 'completed';
      this.doSubmit();
    },
    /*
    async doNotifyVrResearch() {
      await this.validateNotifyVrResearch();
      this.model.status = 'vRUploadSignedDocuments';
      this.doSubmit();
    }, */

    async doSave() {
      await this.validateUploadSignedDocuments();
      this.doSubmit();
    },

    async validateRelease() {
      return this.$refs.formRelease.validate();
    },

    async validateUploadSignedDocuments() {
      return this.$refs.formUploadSignedDocuments.validate();
    },
  },
};
</script>

<style></style>
