/**
 * List of Contract statuses.
 */
class ReleaseStatus {
    static get values() {
      return {   
        releaseRequested: 'releaseRequested',
        released: 'released',
        rejected: 'rejected',
        revisionRequested: 'revisionRequested',
        completed: 'completed',
        vRUploadSignedDocuments: 'vRUploadSignedDocuments',
      };
    }
  }
  
  module.exports = ReleaseStatus;
  